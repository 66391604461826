:root {
  /* Color Pallet */
  --primary: #cf5b1f;

  /* Component layers */
  --00dp: #121212;
  --01dp: #1f1f1f;
  --02dp: #242424;
  --03dp: #262626;
  --04dp: #292929;

  /* Text opacity on dark backgrounds */
  --high-emphasis: 0.87;
  --medium-emphasis: 0.6;
  --disabled-text: 0.38;

  /* Text Color */
  --on-primary-light: rgba(0, 0, 0, var(--high-emphasis));
  --on-primary-medium: rgba(0, 0, 0, var(--medium-emphasis));
  --on-primary-disabled: rgba(0, 0, 0, var(--disabled-text));

  --on-background-light: rgba(255, 255, 255, var(--high-emphasis));
  --on-background-medium: rgba(255, 255, 255, var(--medium-emphasis));
  --on-background-disabled: rgba(255, 255, 255, var(--disabled-text));
}

a {
  color: var(--primary) !important;
  text-decoration-color: var(--primary) !important;
}

.text-attack-red {
  color: #dc3545 !important;
}

.text-mtg-orange {
  color: #cf5b1f !important;
}
.text-poison-green {
  color: #24a824 !important;
}
.text-health-red {
  color: #ff4d4d !important;
}

.text-commander-yellow {
  color: #e6e619 !important;
}

.border-commander-yellow {
  border-color: #e6e619 !important;
}

/* .marko-one-regular {
  font-family: "Marko One", serif;
  font-weight: 400;
  font-style: normal;
} */

.background-button:focus,
.background-button:active,
.background-button:hover {
  border: 0;
}

@keyframes dim {
  0% {
    background-color: rgb(43, 47, 51);
  }
  100% {
    background-color: rgb(33, 37, 41);
  }
}

.background-button:active {
  animation: dim 0.25s ease-out forwards;
}

.border-mtg-orange {
  border-color: var(--primary) !important;
  border-width: 6px !important;
}

.level-0 {
  background-color: var(--00dp) !important;
  color: var(--on-background) !important;
}
.level-1 {
  color: var(--on-background) !important;
  background-color: var(--01dp);
}
.level-2 {
  color: var(--on-background) !important;
  background-color: var(--02dp);
}
.level-3 {
  color: var(--on-background) !important;
  background-color: var(--03dp);
}
.level-4 {
  color: var(--on-background) !important;
  background-color: var(--04dp);
}

body {
  background-color: var(--00dp) !important;
}

.App {
  height: 100vh;
}

.nav-service-logo {
  height: 30px;
}

.margin-top-for-nav {
  margin-top: 52px;
}

.App-logo {
  width: 100%;
  height: auto;
  text-align: center;
  pointer-events: none;
}

.App-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
