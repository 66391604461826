/* .player-content-nav {
  width: 38%;
} */

.player-content-nav-item {
  border-left: 0;
  border-radius: 0 !important;
  background-color: var(--bs-card-cap-bg); /* same as card header */
  &:last-child {
    border-bottom-right-radius: 6px !important;
  }
}

.selected {
  background-color: var(--bs-card-color) !important; /* same as card body */
}

.z-popover {
  z-index: 1070;
}

.bottom-commander-button {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.top-commander-button {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
